import React from "react";
import times from "lodash.times";

import Logo from "../Logo/Logo";

import homeVideoHevc from "../../videos/home/home-h265.mp4";
import homeVideoWebm from "../../videos/home/home-vp9.webm";
import homeVideoMp4 from "../../videos/home/home-h264.mp4";
import homePoster from "../../videos/home/home-poster.jpg";

import styles from "./Home.module.css";

const Home = ({ marquee }) => (
  <div className={styles.Home}>
    {/**
     * React does not guarantee that muted attribute will be set
     * in the actual DOM so we use `dangerouslySetInnerHtml` to
     * circumvent this issue.
     * Codecs supported:
     * H265 (HEVC): High quality codec for MacOS and iOS
     * VP9 (WebM): High quality codec for Android and Chrome
     * H264: Fallback for old browsers and operative systems.
     */}
    <div
      className={styles.videoWrapper}
      dangerouslySetInnerHTML={{
        __html: `
        <video autoPlay muted loop playsinline poster="${homePoster}>
          <source src="${homeVideoHevc}" type="video/mp4; codecs="hevc" />
          <source src="${homeVideoWebm}" type="video/webm; codecs="vp9" />
          <source src="${homeVideoMp4}" type="video/mp4" />
        </video>`,
      }}
    />
    <div className={styles.HomeLayout}>
      <Logo className={styles.logo} />
      <div className={styles.HomeMarqueeClaim}>
        <h1 className={styles.HomeMarqueeClaimInner}>
          {times(4, (i) => (
            <span key={i}>{marquee}</span>
          ))}
        </h1>
      </div>
    </div>
  </div>
);

export default Home;
