import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Home from "../components/Home/Home";

const IndexPage = () => (
  <Layout home>
    <SEO title="Home" />
    <Home marquee="We are a full production photographic studio based in Milan" />
  </Layout>
);

export default IndexPage;
